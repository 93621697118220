<template>
  <div style="max-height: 500px; overflow-y: auto; line-height: initial !important">
    <div class="text-center status-card">
      <div v-if="purchaseData">
        <div style="max-height: 450px; overflow: auto">
          <div id="print-cards" style="word-break: break-all">
            <!--          Direct Recharge Print-->
            <div style="padding: 0px 5px" v-if="printReportType == 3">
              <div class="table-holder">
                <p v-if="isDuplicate" class="receipt-duplicate">Duplicate</p>
                <table>
                  <tr>
                    <td colspan="2">
                      <div class="table-img-amount-holder">
                        <p class="table-print-amount">
                          {{ purchaseData.provider }}
                        </p>
                        <p class="table-recharge-amount">
                          {{ purchaseData.label }}
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <div class="table-img-amount-holder">
                        <p class="table-recharge-success">* {{ $t("RechargeSuccessful") }} *</p>

                        <p class="table-recharge-amount">
                          {{ purchaseData.amount | germanNumberFormat }}
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="table-td-white-space">
                      <p class="table-inline-title">
                        {{ $t("Mobile") }}
                      </p>
                    </td>
                    <td class="table-td-100">
                      <p class="table-inline">
                        {{ purchaseData.mobile }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td class="table-td-white-space">
                      <p class="table-inline-title">
                        {{ $t("TransactionID") }}
                      </p>
                    </td>
                    <td class="table-td-100">
                      <p class="table-inline">
                        {{ purchaseData.tx_id }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td class="table-td-white-space">
                      <p class="table-inline-title">
                        {{ $t("Date") }}
                      </p>
                    </td>
                    <td class="table-td-100">
                      <p class="table-inline">
                        {{ purchaseData.created | dateTimeNoSecFormat }}
                      </p>
                    </td>
                  </tr>
                  <tr v-if="activeUserInfo.shop_details_to_print">
                    <td colspan="2">
                      <p class="table-address">
                        {{ $localStorage.get("organisation") }}<br />
                        {{ $localStorage.get("address").replace("null", "-") }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <p class="table-tag-line">Umtausch und Rücknahme sind ausgeschlossen.</p>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <!--          Direct Recharge Print-->

            <!--          CashCard Print-->

            <div v-if="!mergeReceipts" style="word-break: break-all" class="font-mono">
              <div
                style="padding: 0px 5px"
                v-if="printReportType == 0"
                v-for="inventory in purchaseData.inventory_list ||
                purchaseData.transaction_pins ||
                purchaseData.json_data"
              >
                <div class="table-holder font-mono">
                  <p v-if="isDuplicate" class="receipt-duplicate">Duplicate</p>
                  <table>
                    <tr>
                      <td colspan="2">
                        <div class="table-img-amount-holder">
                          <img
                            class="table-print-logo"
                            v-if="inventory.product_image"
                            :src="'https://pjtelesoftgmbh.de' + inventory.product_image"
                            :alt="inventory.product_name"
                          />
                          <p style="font-size: 24px; font-weight: bold" v-else>
                            {{ purchaseData.product }}
                          </p>
                          <p class="table-print-amount">
                            {{
                              (inventory.product_configuration_value || purchaseData.value)
                                | germanNumberFormat
                            }}
                          </p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <p class="table-pin-title">
                          {{ $t("PinNumber") }}
                        </p>
                        <p
                          class="table-pin"
                          :id="inventory.pin == undefined ? inventory.pinnumber : inventory.pin"
                        >
                          {{ inventory.pin == undefined ? inventory.pinnumber : inventory.pin }}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <p class="table-instructions-title">
                          {{ $t("Instructions") }}
                        </p>
                        <p class="table-instructions" v-html="inventory.instructions">
                          <!--                          {{ inventory.instructions }}-->
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td class="table-td-white-space">
                        <p class="table-inline-title">
                          {{ $t("Batch") }}
                        </p>
                      </td>
                      <td class="table-td-100">
                        <p class="table-inline">
                          {{
                            purchaseData.dynamic_type == 1
                              ? inventory.serial
                              : inventory.batchnumber
                          }}
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <td class="table-td-white-space">
                        <p class="table-inline-title">
                          {{ $t("Hotline") }}
                        </p>
                      </td>
                      <td class="table-td-100">
                        <p class="table-inline">
                          {{ inventory.customer_care }}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td class="table-td-white-space">
                        <p class="table-inline-title">
                          {{ $t("Date") }}
                        </p>
                      </td>
                      <td class="table-td-100">
                        <p class="table-inline">
                          {{ purchaseData.updated | dateTimeNoSecFormat }}
                        </p>
                      </td>
                    </tr>
                    <!-- QR Code here-->
                    <tr v-if="inventory.pin_qr_code != null">
                      <td colspan="2">
                        <p class="font-bold text-center">QR Code Scannen und Aufladen</p>
                        <div class="table-img-amount-holder">
                          <div class="table-print-logo">
                            <!-- <qrcode-vue render-as="svg" :value="Buffer.from(inventory.pin_qr_code, 'base64').toString()" size="100" level="H"></qrcode-vue> -->
                            <qrcode-vue
                              render-as="svg"
                              :value="'tel:' + inventory.pin_qr_code"
                              size="100"
                              level="H"
                            ></qrcode-vue>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="activeUserInfo.shop_details_to_print">
                      <td colspan="2">
                        <p class="table-address">
                          {{ $localStorage.get("organisation") }}<br />
                          {{ $localStorage.get("address").replace("null", "-") }}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <p class="table-tag-line">Umtausch und Rücknahme sind ausgeschlossen.</p>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div v-else>
              <div style="padding: 0px 5px" v-if="printReportType == 0">
                <div class="table-holder">
                  <p v-if="isDuplicate" class="receipt-duplicate">Duplicate</p>
                  <table>
                    <tr>
                      <td colspan="2">
                        <div class="table-img-amount-holder">
                          <img
                            class="table-print-logo"
                            v-if="
                              purchaseData.inventory_list ||
                              purchaseData.transaction_pins ||
                              purchaseData.json_data
                            "
                            :src="
                              (purchaseData.inventory_list || purchaseData.transaction_pins)[0]
                                .product_image
                            "
                            :alt="
                              (purchaseData.inventory_list || purchaseData.transaction_pins)[0]
                                .product_name
                            "
                          />

                          <p style="font-size: 24px; font-weight: bold" v-else>
                            {{ purchaseData.product }}
                          </p>

                          <p
                            class="table-print-amount"
                            v-if="purchaseData.inventory_list || purchaseData.transaction_pins"
                          >
                            {{
                              (purchaseData.inventory_list || purchaseData.transaction_pins)[0]
                                .product_configuration_value | germanNumberFormat
                            }}
                          </p>

                          <p class="table-print-amount" v-else>
                            {{ purchaseData.value | germanNumberFormat }}
                          </p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <p class="table-pin-title">
                          {{ $t("PinNumber") }}
                        </p>
                        <template
                          v-if="purchaseData.inventory_list || purchaseData.transaction_pins"
                          v-for="inventory in purchaseData.inventory_list ||
                          purchaseData.transaction_pins"
                        >
                          <p
                            class="table-pin"
                            :id="inventory.pin == undefined ? inventory.pinnumber : inventory.pin"
                          >
                            {{ inventory.pin == undefined ? inventory.pinnumber : inventory.pin }}
                          </p>
                        </template>
                        <template v-else v-for="inventory in purchaseData.json_data">
                          <p class="table-pin">
                            {{ inventory.pin == undefined ? inventory.pinnumber : inventory.pin }}
                          </p>
                        </template>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <p class="table-instructions-title">
                          {{ $t("Instructions") }}
                        </p>
                        <p
                          class="table-instructions"
                          v-if="purchaseData.inventory_list || purchaseData.transaction_pins"
                          v-html="
                            (purchaseData.inventory_list || purchaseData.transaction_pins)[0]
                              .instructions
                          "
                        >
                          <!--                          {{ (purchaseData.inventory_list || purchaseData.transaction_pins)[0].instructions }}-->
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td class="table-td-white-space">
                        <p class="table-inline-title">
                          {{ $t("Batch") }}
                        </p>
                      </td>
                      <td class="table-td-100">
                        <template
                          v-if="purchaseData.inventory_list || purchaseData.transaction_pins"
                          v-for="inventory in purchaseData.inventory_list ||
                          purchaseData.transaction_pins"
                        >
                          <p class="table-inline">
                            {{
                              purchaseData.dynamic_type == 1
                                ? inventory.serial
                                : inventory.batchnumber
                            }}
                          </p>
                        </template>
                        <template v-else v-for="inventory in purchaseData.json_data">
                          <p class="table-inline">
                            {{ inventory.batchnumber }}
                          </p>
                        </template>
                      </td>
                    </tr>
                    <tr>
                      <td class="table-td-white-space">
                        <p class="table-inline-title">
                          {{ $t("Hotline") }}
                        </p>
                      </td>
                      <td class="table-td-100">
                        <p
                          class="table-inline"
                          v-if="purchaseData.inventory_list || purchaseData.transaction_pins"
                        >
                          {{
                            (purchaseData.inventory_list || purchaseData.transaction_pins)[0]
                              .customer_care
                          }}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td class="table-td-white-space">
                        <p class="table-inline-title">
                          {{ $t("Date") }}
                        </p>
                      </td>
                      <td class="table-td-100">
                        <p class="table-inline">
                          {{ purchaseData.updated | dateTimeNoSecFormat }}
                        </p>
                      </td>
                    </tr>
                    <!-- QR Code here-->
                    <tr v-if="inventory.pin_qr_code != null">
                      <td colspan="2">
                        <p class="font-bold text-center">QR Code Scannen und Aufladen</p>
                        <div class="table-img-amount-holder">
                          <div class="table-print-logo">
                            <!-- <qrcode-vue :value="inventory.pin" size="100" level="H" render-as="svg"></qrcode-vue> -->
                            <qrcode-vue
                              render-as="svg"
                              :value="'tel:' + inventory.pin_qr_code"
                              size="100"
                              level="H"
                            ></qrcode-vue>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="activeUserInfo.shop_details_to_print">
                      <td colspan="2">
                        <p class="table-address">
                          {{ $localStorage.get("organisation") }}<br />
                          {{ $localStorage.get("address").replace("null", "-") }}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <p class="table-tag-line">Umtausch und Rücknahme sind ausgeschlossen.</p>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <!--          CashCard Print-->

            <!-- GiftCard Print  -->
            <div style="padding: 0px 5px" v-if="printReportType == 4">
              <div class="table-holder">
                <p v-if="isDuplicate" class="receipt-duplicate">Duplicate</p>
                <table>
                  <tr>
                    <td colspan="2">
                      <div class="table-img-amount-holder">
                        <p class="table-recharge-success">
                          {{ purchaseData.product_name }}
                        </p>
                        <p class="table-print-amount">
                          {{ purchaseData.value | germanNumberFormat }}
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="table-td-white-space">
                      <p class="table-inline-title">
                        {{ $t("Serial") }}
                      </p>
                    </td>
                    <td class="table-td-100">
                      <p class="table-inline">
                        {{ purchaseData.serial_number }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <p class="table-tag-line">{{ $t("CardActivatedSuccessfully") }}</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="table-td-white-space">
                      <p class="table-inline-title">
                        {{ $t("Date") }}
                      </p>
                    </td>
                    <td class="table-td-100">
                      <p class="table-inline">
                        {{ purchaseData.created | dateTimeNoSecFormat }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td class="table-td-white-space">
                      <p class="table-inline-title">
                        {{ $t("SellingPrice") }}
                      </p>
                    </td>
                    <td class="table-td-100">
                      <p class="table-inline">
                        {{ purchaseData.total_amount | germanNumberFormat }}
                      </p>
                    </td>
                  </tr>
                  <tr v-if="activeUserInfo.shop_details_to_print">
                    <td colspan="2">
                      <p class="table-address">
                        {{ $localStorage.get("organisation") }}<br />
                        {{ $localStorage.get("address").replace("null", "-") }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <p class="table-tag-line">Umtausch und Rücknahme sind ausgeschlossen.</p>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <!-- GiftCard Print  -->

            <!-- International TopUP Print -->
            <div style="padding: 0px 5px" v-if="printReportType == 2">
              <div v-if="purchaseData.prices" class="table-holder">
                <p v-if="isDuplicate" class="receipt-duplicate">Duplicate</p>
                <table>
                  <tr>
                    <td colspan="2">
                      <div class="table-img-amount-holder">
                        <p class="table-print-amount">
                          {{ purchaseData.product.operator.name }}
                        </p>

                        <p class="table-recharge-success">* {{ $t("RechargeSuccessful") }} *</p>

                        <p class="table-recharge-amount">
                          {{ purchaseData.prices.retail.amount | germanNumberFormat }}
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="table-td-white-space">
                      <p class="table-inline-title">
                        {{ $t("Mobile") }}
                      </p>
                    </td>
                    <td class="table-td-100">
                      <p class="table-inline">
                        {{ purchaseData.credit_party_identifier.mobile_number }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td class="table-td-white-space">
                      <p class="table-inline-title">
                        {{ $t("TransactionID") }}
                      </p>
                    </td>
                    <td class="table-td-100">
                      <p class="table-inline">
                        {{ purchaseData.id }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td class="table-td-white-space">
                      <p class="table-inline-title">
                        {{ $t("DateAndTime") }}
                      </p>
                    </td>
                    <td class="table-td-100">
                      <p class="table-inline">
                        {{ purchaseData.created | dateFormat }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td class="table-td-white-space">
                      <p class="table-inline-title">
                        {{ $t("CellCredit") }}
                      </p>
                    </td>
                    <td class="table-td-100">
                      <p class="table-inline" v-for="(benefit, ix) in purchaseData.benefits">
                        <span v-if="benefit.additional_information">
                          {{ benefit.additional_information }}
                        </span>
                        <span v-else>
                          {{ benefit.type }} {{ benefit.amount.base }} {{ benefit.unit }}
                        </span>
                      </p>
                    </td>
                  </tr>
                  <tr v-if="activeUserInfo.shop_details_to_print">
                    <td colspan="2">
                      <p class="table-address">
                        {{ $localStorage.get("organisation") }}<br />
                        {{ $localStorage.get("address").replace("null", "-") }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <p class="table-tag-line">Umtausch und Rücknahme sind ausgeschlossen.</p>
                    </td>
                  </tr>
                </table>
              </div>
              <div v-else class="table-holder">
                <p v-if="isDuplicate" class="receipt-duplicate">Duplicate</p>
                <table>
                  <tr>
                    <td colspan="2">
                      <div class="table-img-amount-holder">
                        <p class="table-print-amount">
                          {{ purchaseData.provider_name_with_country }}
                        </p>

                        <p class="table-recharge-success">* {{ $t("RechargeSuccessful") }} *</p>

                        <p class="table-recharge-amount">
                          {{ purchaseData.transaction_details.SendValue | germanNumberFormat }}
                        </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="table-td-white-space">
                      <p class="table-inline-title">
                        {{ $t("Mobile") }}
                      </p>
                    </td>
                    <td class="table-td-100">
                      <p class="table-inline">
                        {{ purchaseData.recharge_to }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td class="table-td-white-space">
                      <p class="table-inline-title">
                        {{ $t("TransactionID") }}
                      </p>
                    </td>
                    <td class="table-td-100">
                      <p class="table-inline">
                        {{ purchaseData.transaction_id }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td class="table-td-white-space">
                      <p class="table-inline-title">
                        {{ $t("Date") }}
                      </p>
                    </td>
                    <td class="table-td-100">
                      <p class="table-inline">
                        {{ purchaseData.creation_date | dateTimeNoSecFormat }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td class="table-td-white-space">
                      <p class="table-inline-title">
                        {{ $t("CellCredit") }}
                      </p>
                    </td>
                    <td class="table-td-100">
                      <p class="table-inline">
                        {{ purchaseData.transaction_details.ReceiveValue }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td class="table-td-white-space">
                      <p class="table-inline-title">
                        {{ $t("CellCurrency") }}
                      </p>
                    </td>
                    <td class="table-td-100">
                      <p class="table-inline">
                        {{ purchaseData.transaction_details.ReceiveCurrencyIso }}
                      </p>
                    </td>
                  </tr>
                  <tr v-if="activeUserInfo.shop_details_to_print">
                    <td colspan="2">
                      <p class="table-address">
                        {{ $localStorage.get("organisation") }}<br />
                        {{ $localStorage.get("address").replace("null", "-") }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <p class="table-tag-line">Umtausch und Rücknahme sind ausgeschlossen.</p>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <!-- International TopUP Print  -->

            <!-- FlixBus Print  -->
            <div style="padding: 0px 5px" v-if="printReportType == 5">
              <template v-for="(trip, index) in purchaseData.ticket_for_order.order.trips">
                <div class="table-holder">
                  <p v-if="isDuplicate" class="receipt-duplicate">Duplicate</p>
                  <table>
                    <tr>
                      <td colspan="2">
                        <div class="table-img-amount-holder">
                          <p class="table-flixbus-top-normal">
                            {{ $t("BookingNumber") }}
                          </p>
                          <p class="table-flixbus-top-id">
                            {{ purchaseData.ticket_for_order.order.id }}
                          </p>
                          <p class="">
                            <span class="table-flixbus-top-normal">{{ $t("TicketPrice") }} : </span>
                            <span class="table-flixbus-top-id">{{
                                purchaseData.added_passengers.cart.price.total | germanNumberFormat
                              }}</span>
                          </p>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td class="table-td-white-space">
                        <p class="table-flixbus-inline-title">
                          {{ $t("Departure") }}
                        </p>
                      </td>
                      <td class="table-td-100">
                        <p class="table-flixbus-inline">
                          {{ getFullTime(trip.departure.timestamp, trip.departure.tz) }}
                        </p>
                        <p style="word-break: break-word">
                          <strong>{{ trip.departure_station.full_address }}</strong>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td class="table-td-white-space">
                        <p class="table-flixbus-inline-title">
                          {{ $t("Arrival") }}
                        </p>
                      </td>
                      <td class="table-td-100">
                        <p class="table-flixbus-inline">
                          {{ getFullTime(trip.arrival.timestamp, trip.arrival.tz) }}
                        </p>
                        <p style="word-break: break-word">
                          <strong>{{ trip.arrival_station.full_address }}</strong>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" class="td-no-padding">
                        <table style="width: 100%" class="table-no-border" border="0">
                          <tbody>
                          <tr>
                            <td>{{ $t("Adults") }}</td>
                            <td>{{ getAdultTypeCount(trip.passengers) }}</td>
                            <td>{{ $t("Children") }}</td>
                            <td>{{ getChildTypeCount(trip.passengers) }}</td>
                            <td>{{ $t("Bicycle") }}</td>
                            <td>{{ getBikesTypeCount(trip.passengers) }}</td>
                          </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" class="td-no-padding">
                        <p class="table-flixbus-top-normal py-1">
                          {{ $t("PassengerInformation") }}
                        </p>
                        <table style="width: 98%">
                          <tbody>
                          <tr>
                            <td>S.No</td>
                            <td>Name</td>
                            <td>Type</td>
                            <td>DOB</td>
                          </tr>
                          <tr style="font-weight: bold" v-for="(pass, index) in trip.passengers">
                            <td>{{ index + 1 }}</td>
                            <td>{{ pass.firstname }} {{ pass.lastname }}</td>
                            <td>{{ pass.type }}</td>
                            <td>{{ pass.birthdate }}</td>
                          </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <p class="table-flix-info-title py-1">
                          {{ $t("Description") }}
                        </p>
                        <div class="text-center">
                          <p v-html="trip.products_description_html"></p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <p class="table-flix-info-title py-1">
                          {{ $t("LineDirection") }}
                        </p>
                        <div class="text-center">
                          <p>{{ trip.line_direction }}</p>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="trip.operated_by">
                      <td colspan="2">
                        <p style="text-align: center; font-size: 24px" class="py-1">
                          <strong>{{ getOperatedBy(trip.operated_by) }}</strong>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <div class="table-flix-info-title">
                          <p>{{ $t("InfoBlocks") }}</p>
                        </div>
                        <div
                          style="text-align: start"
                          v-for="(info, index) in purchaseData.ticket_for_order.order.info_blocks"
                        >
                          <p>
                            <strong>{{ info.name | title }} : </strong
                            ><span v-html="info.html"></span>
                          </p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" class="" style="margin: auto">
                        <img
                          :src="purchaseData.ticket_for_order.order.qr_image"
                          style="margin: auto; display: flex"
                          width="40%"
                        />
                      </td>
                    </tr>
                    <tr v-if="activeUserInfo.shop_details_to_print">
                      <td colspan="2">
                        <p class="table-address">
                          {{ $localStorage.get("organisation") }}<br />
                          {{ $localStorage.get("address").replace("null", "-") }}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <p class="table-tag-line">Umtausch und Rücknahme sind ausgeschlossen.</p>
                      </td>
                    </tr>
                  </table>
                </div>
              </template>
              <div style="padding-top: 100px"></div>
            </div>
            <!-- FlixBus Print  -->
          </div>
        </div>
      </div>
    </div>

    <div class="vs-row flex justify-center">
      <vs-button class="ml-2 mt-3" color="primary" id="print-card" @click="printCard()">{{
          $t("Print")
        }}</vs-button>
      <vs-button class="ml-4 mt-3" color="danger" @click="$emit('close')">{{
          $t("Close")
        }}</vs-button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import QrcodeVue from "qrcode.vue";

export default {
  name: "ReceiptPrint",
  data() {
    return {
      pinDownload: false,
      mergeReceipts: false,
    };
  },
  props: {
    purchaseData: {
      type: [Object, Array],
    },
    isDuplicate: {
      default: false,
      type: Boolean,
    },
    printReportType: {
      type: [Number, String],
    },
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  components: {
    QrcodeVue,
  },
  mounted() {
    if (localStorage.getItem("merge_receipts") == null) {
      localStorage.setItem("merge_receipts", "false");
    }
    this.mergeReceipts = localStorage.getItem("merge_receipts") == "true";
    this.pinDownload = localStorage.getItem("pin_download") == "true";

    if (this.pinDownload && this.printReportType == 0) {
      this.$emit("close");

      let s = "product,denomination,batch,pin";
      let d = this.purchaseData.inventory_list || this.purchaseData.transaction_pins;
      let file_name = "";
      try {
        file_name = `${this.purchaseData.transaction_id}_${d[0].product_name}_${d[0].product_configuration_value}`;
      } catch (e) {
        file_name = `${this.purchaseData.transaction_id}`;
      }
      for (let index in d) {
        let inventory = d[index];
        let pin = inventory.pin == undefined ? inventory.pinnumber : inventory.pin;
        let batch =
          this.purchaseData.dynamic_type == 1
            ? inventory.serial
            : inventory.batchnumber || inventory.batch;
        s = `${s}\n${d[0].product_name},${d[0].product_configuration_value},${batch},${pin}`;
      }
      const hiddenElement = document.createElement("a");
      hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(s)}`;
      hiddenElement.target = "_blank";
      hiddenElement.download = `${file_name}.txt`;
      hiddenElement.click();
    }

    document.addEventListener("keyup", (event) => {
      if (event.key === "Escape") {
        this.$emit("close");
      }
    });
  },
  methods: {
    getOperatedBy(operatorArray) {
      let operatedBy = "";
      operatorArray.forEach((operator, index) => {
        if (operator.label == "FlixTrain GmbH") {
          operatedBy = "FLIXTRAIN";
        } else {
          operatedBy = "FLIXBUS";
        }
      });
      return operatedBy;
    },
    getFullTime(milli, tz) {
      const d = new Date(milli * 1000);
      let t = tz.replace("GMT", "");
      const op = t[0];
      t = t.replace(op, "");
      const s = t.split(":");
      let m = moment(d.toUTCString()).add(Number(s[0]), "hours");
      m = m.add(Number(s[1]), "minutes");

      let timeOne = m.toISOString().split("T");
      let timeDate = timeOne[0].split("-");
      let timeTwo = timeOne[1].split(":");

      return `${timeDate[2]}.${timeDate[1]}.${timeDate[0]}, ${timeTwo[0]}:${timeTwo[1]}`;
    },
    getAdultTypeCount(data) {
      let c = 0;
      data.forEach((d) => {
        if (d.type == "adult") {
          c += 1;
        }
      });
      return c;
    },
    getChildTypeCount(data) {
      let c = 0;
      data.forEach((d) => {
        if (d.type == "children") {
          c += 1;
        }
      });
      return c;
    },
    getBikesTypeCount(data) {
      let c = 0;
      data.forEach((d) => {
        if (d.type == "bikes") {
          c += 1;
        }
      });
      return c;
    },
    printCard() {
      try {
        const divContents = document.getElementById("print-cards").innerHTML;
        const a = window.open("", "");
        a.document.write("<html>");
        a.document.write("<body >");
        a.document.write(
          "<style> body{font-family:Arial !important;margin:0 !important}.table-holder table{min-width:196px;max-width:196px;line-height:1.3}.table-holder table td{padding:0.2rem;font-weight:bold}table{border-collapse:collapse}.td-no-padding{padding:0 !important}.table-no-border{width:100%}.table-no-border td,.table-no-border th{border-top:none;border-bottom:none}td,th{border:2px solid #000;padding:0.3rem;text-align:left}.table-td-100{width:100%}.table-td-white-space{white-space:nowrap}.table-print-logo{padding:4px;width:60%;filter: grayscale(1);object-fit:cover;text-align:center;margin:0 auto}.table-img-amount-holder{text-align:center;padding-top:0.5rem}.table-print-amount{font-size:30px;font-weight:bolder;margin:0}.table-pin-title{font-weight:700;font-size:13px;text-align:start !important}.table-pin{font-size:16px;text-align:center;font-weight:bold;font-family:Arial !important;line-height:20px;}.table-instructions-title{font-weight:700;font-size:13px;text-align:start !important}.table-instructions{font-size:10px;text-align:center;white-space: pre-wrap;}.table-inline-title{font-weight:700;font-size:10px;text-align:start !important}.table-inline{font-size:12px;text-align:start}.table-address{font-size:12px;text-align:center}.table-tag-line{font-size:10px;text-align:center;font-weight:bold}.table-recharge-success{font-size:14px;font-weight:700}.table-recharge-amount{font-size:20px;text-align:center}.table-flixbus-top-normal{font-size:12px;text-align:center}.table-flixbus-top-id{font-size:14px;font-weight:700;text-align:center}.table-flixbus-inline-title{font-size:12px}.table-flixbus-inline{font-size:12px;font-weight:700}.table-flix-info-title{font-size:12px;font-weight:700}p{margin:0}.table-holder{position:relative}.receipt-duplicate{position:absolute;bottom:0;transform-origin:0 0;transform:rotate(-45deg);font-size:50px;opacity:0.4}</style>"
        );
        a.document.write(divContents);
        a.document.write("</body></html>");

        if (this.printReportType == 0) {
          let pins = this.purchaseData.inventory_list || this.purchaseData.transaction_pins;
          pins.forEach((inventory) => {
            let pin = a.document.getElementById(
              inventory.pin == undefined ? inventory.pinnumber : inventory.pin
            ).innerText;
            if (pin != (inventory.pin == undefined ? inventory.pinnumber : inventory.pin)) {
              a.document.getElementById(
                inventory.pin == undefined ? inventory.pinnumber : inventory.pin
              ).innerText = inventory.pin == undefined ? inventory.pinnumber : inventory.pin;
            }
          });
        }

        setTimeout(() => {
          a.print();
          a.close();
        }, 600);
        this.$emit("close");
      } catch (e) {}
    },
  },
};
</script>

<style scoped lang="scss">
.status-card {
  min-width: 340px;
  max-width: 410px;
}
.table-holder table {
  min-width: 330px;
  max-width: 400px;
  padding: 2px;

  td {
    padding: 0.3rem 0.5rem;
  }
}

table {
  border-collapse: collapse;
}
.td-no-padding {
  padding: 0 !important;
}
.table-no-border {
  width: 100%;

  td,
  th {
    border-top: none;
    border-bottom: none;
  }
}

td,
th {
  border: 1px solid #999;
  padding: 0.5rem;
  text-align: left;
}

.table-td-100 {
  width: 100%;
}

.table-td-white-space {
  white-space: nowrap;
}

.table-print-logo {
  padding: 4px;
  width: 30%;
  object-fit: cover;
  text-align: center;
  margin: 0 auto;
}

.table-img-amount-holder {
  text-align: center;
}

.table-print-amount {
  font-size: 32px;
  font-weight: bolder;
  margin: 0;
}

.table-pin-title {
  font-weight: bold;
  font-size: 18px;
  text-align: start !important;
}

.table-pin {
  font-size: 30px;
  text-align: center;
  font-weight: bold;
  font-family: "Times New Roman";
  line-height: 34px;
  //height: 35px;
  //margin-bottom: 8px !important;
}

.table-instructions-title {
  font-weight: bold;
  font-size: 18px;
  text-align: start !important;
}

.table-instructions {
  font-size: 20px;
  white-space: pre-wrap;
  text-align: center;
}

.table-inline-title {
  font-weight: bold;
  font-size: 18px;
  text-align: start !important;
}

.table-inline {
  font-size: 16px;
  text-align: start;
}

.table-address {
  font-size: 18px;
  text-align: center;
}

.table-tag-line {
  font-size: 11px;
  font-weight: bold;
  padding: 2px 4px;
  text-align: center;
}

.table-recharge-success {
  font-size: 20px;
  font-weight: bold;
}

.table-recharge-amount {
  font-size: 24px;
  text-align: center;
}
.table-flixbus-top-normal {
  font-size: 18px;
  text-align: center;
}
.table-flixbus-top-id {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
.table-flixbus-inline-title {
  font-size: 16px;
}
.table-flixbus-inline {
  font-size: 16px;
  font-weight: bold;
}
.table-flix-info-title {
  font-size: 16px;
  font-weight: bold;
}
p {
  margin: 0;
}
.table-holder {
  position: relative;
}
.receipt-duplicate {
  position: absolute;
  bottom: 0;
  transform-origin: 0 0;
  transform: rotate(-45deg);
  font-size: 66px;
  opacity: 0.1;
}
</style>
